import { AuthInterface } from '../../services/graphql/auth/definitions/auth-interface';

export function authStoreToken(authInfo: AuthInterface): void {
  localStorage.setItem('authToken', JSON.stringify(authInfo));
}

export function authRestoreToken(): AuthInterface | null {
  const authToken = localStorage.getItem('authToken');
  if (authToken === 'undefined' || authToken === null) {
    return null;
  }
  return JSON.parse(authToken);
}

export function authClearToken(): void {
  localStorage.removeItem('authToken');
}

export function recaptchaStoreKey(recaptchaKey: string): void {
  localStorage.setItem('recaptchaKey', recaptchaKey);
}

export function recaptchaRestoreKey(): string | null {
  const recaptchaKey = localStorage.getItem('recaptchaKey');
  if (recaptchaKey === 'undefined' || recaptchaKey === null) {
    return null;
  }
  return recaptchaKey;
}

export function recaptchaClearKey(): void {
  localStorage.removeItem('recaptchaKey');
}

export function authStoreRefreshToken(token: string): void {
  localStorage.setItem('refreshToken', token);
}

export function authRestoreRefreshToken(): AuthInterface | null {
  const refreshToken = localStorage.getItem('refreshToken');
  if (refreshToken === 'undefined' || refreshToken === null) {
    return null;
  }
  return JSON.parse(refreshToken);
}

export function authClearRefreshToken(): void {
  localStorage.removeItem('refreshToken');
}

export function authStoreApiV2Token(token: string): void {
  localStorage.setItem('authTokenApiV2', token);
}

export function authRestoreApiV2Token(): AuthInterface | null {
  const apiV2Token = localStorage.getItem('authTokenApiV2');
  if (apiV2Token === 'undefined' || apiV2Token === null) {
    return null;
  }
  return JSON.parse(apiV2Token);
}

export function authClearApiV2Token(): void {
  localStorage.removeItem('authTokenApiV2');
}