import { Injectable } from '@angular/core';
import { AuthInterface } from '../rest/auth/definitions/auth-interface';
import { authClearToken } from '../../utils/auth-store/auth-store';
import { AuthorizeService } from '../rest/auth/authorize.service';
import { LogoutService } from '../graphql/auth/logout.service';
import { LoggerService } from '../logger.service';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class AuthApiV2Service {
  constructor(
    private authorizeService: AuthorizeService,
  ) {}

  authorize(username: string, password: string): Promise<any> {
    return this.authorizeService
      .authorize(username, password)
      .pipe(
        map((authData) => {
          return this._mapAuth(authData);
        }),
        catchError((error) => {
          return of({ success: false, error: error });
        })
      )
      .toPromise();
  }

  private _mapAuth(authData): AuthInterface {
    const auth = { ...authData };
    if (!!authData) {
      auth.tokenValidTo = new Date(auth.tokenValidTo);
      auth.refreshTokenValidTo = new Date(auth.refreshTokenValidTo);
    }
    return auth;
  }
}
