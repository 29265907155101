import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GraphqlModule } from './shared/services/graphql/graphql.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthGuard, ComponentsGalleryGuard } from './shared';
import { TasksService } from './shared/services/tasks/tasks.service';
import { LoggerService } from './shared/services/logger.service';
import { MatNativeDateModule } from '@angular/material/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { provideApolloClientOptions } from '../graphql/client';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import localeCs from '@angular/common/locales/cs';
import { AuthInterceptor } from './shared/auth.interceptor';
import { AuthApiV2Module } from './shared/services/rest/auth/auth-api-v2.module';
registerLocaleData(localeCs);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  // TODO: better cache by version
  return new TranslateHttpLoader(
    http,
    '/assets/i18n/',
    '.json?v=' + Date.now()
  );
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AuthApiV2Module,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    GraphqlModule,
    MatNativeDateModule,
    MatDialogModule,
    MatIconModule,
    MatToolbarModule,
    CommonModule,
    HttpClientModule,
    OverlayModule,
    MatSnackBarModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AuthGuard,
    ComponentsGalleryGuard,
    TasksService,
    LoggerService,
    { provide: LOCALE_ID, useValue: 'cs-CS' },
    {
      provide: APOLLO_OPTIONS,
      useFactory: provideApolloClientOptions,
      deps: [HttpLink],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
