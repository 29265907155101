import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class AuthorizeService {

  constructor(private client: HttpClient) {}

  authorize(username: string, password: string): Observable<any> {
    return this.client.post(environment.rest.apiV2Url.concat("auth/login"), {username: username, password: password});
  }
}