import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, take, map } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private snackBarShown = false;

  constructor(private router: Router, private snackBar: MatSnackBar) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((e) => {
        if (e.status === 401) {
          this.expiredSessionNavigationAndNotification();
        }
        return throwError(e);
      }),
      map((res) => {
        if (!this.snackBarShown) {
          const status = this.extractStatusFromResponse(res);
          if (status === 401) {
            this.snackBarShown = true;
            this.expiredSessionNavigationAndNotification();
          }
        }
        return res;
      })
    );
  }

  expiredSessionNavigationAndNotification() {
    this.router.navigate(['logout']).then(() => {
      this.snackBar.open(
        'Vaše relace vypršela. Byl(a) jste odhlášen(a).',
        'OK',
        {
          duration: 5000,
        }
      );
    });
  }

  private extractStatusFromResponse(response: any) {
    const status = this.recursivelyFindStatus(response);
    return status;
  }

  private recursivelyFindStatus(object: any): number | undefined {
    if (object && typeof object === 'object') {
      if (object.errors && object.errors.status) {
        return object.errors.status;
      }
      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          const status = this.recursivelyFindStatus(object[key]);
          if (status !== undefined) {
            return status;
          }
        }
      }
    }

    return undefined;
  }
}
