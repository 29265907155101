import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthorizeService } from './authorize.service';
import { AuthApiV2Service } from '../../auth-api-v2/auth-api-v2.service';

@NgModule({
  imports: [CommonModule],
  providers: [AuthApiV2Service, AuthorizeService],
})
export class AuthApiV2Module {}
